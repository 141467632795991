@media print {
    
    .container {
        width: auto !important;
        padding: 0;
    }
    .table-print {
        width: 100%;
        font-size: 12px;
        .print-header {
            display: block !important;
        }
    }
    section.content {
        padding: 0;
    }
    footer,    
    .main-header {
        display: none;
    }
    .box {
        border: none;
        .box-body {
            border-radius: 0;
            padding: 0;
        }
    }

}
