/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.pa0 {
	padding: 0 !important;
}
.pa1 {
	padding: 0.25rem;
}
.pa2 {
	padding: 0.5rem;
}
.pa3 {
	padding: 1rem;
}
.pa4 {
	padding: 2rem;
}
.pa5 {
	padding: 4rem;
}
.pa6 {
	padding: 8rem;
}
.pa7 {
	padding: 16rem;
}
.pl0 {
	padding-left: 0;
}
.pl1 {
	padding-left: 0.25rem;
}
.pl2 {
	padding-left: 0.5rem;
}
.pl3 {
	padding-left: 1rem;
}
.pl4 {
	padding-left: 2rem;
}
.pl5 {
	padding-left: 4rem;
}
.pl6 {
	padding-left: 8rem;
}
.pl7 {
	padding-left: 16rem;
}
.pr0 {
	padding-right: 0;
}
.pr1 {
	padding-right: 0.25rem;
}
.pr2 {
	padding-right: 0.5rem;
}
.pr3 {
	padding-right: 1rem;
}
.pr4 {
	padding-right: 2rem;
}
.pr5 {
	padding-right: 4rem;
}
.pr6 {
	padding-right: 8rem;
}
.pr7 {
	padding-right: 16rem;
}
.pb0 {
	padding-bottom: 0;
}
.pb1 {
	padding-bottom: 0.25rem;
}
.pb2 {
	padding-bottom: 0.5rem;
}
.pb3 {
	padding-bottom: 1rem;
}
.pb4 {
	padding-bottom: 2rem;
}
.pb5 {
	padding-bottom: 4rem;
}
.pb6 {
	padding-bottom: 8rem;
}
.pb7 {
	padding-bottom: 16rem;
}
.pt0 {
	padding-top: 0 !important;
}
.pt1 {
	padding-top: 0.25rem;
}
.pt2 {
	padding-top: 0.5rem !important;
}
.pt3 {
	padding-top: 1rem;
}
.pt4 {
	padding-top: 2rem;
}
.pt4-2 {
	padding-top: 2.2rem;
}
.pt4-75 {
	padding-top: 2.75rem;
}
.pt5 {
	padding-top: 4rem;
}
.pt6 {
	padding-top: 8rem;
}
.pt7 {
	padding-top: 16rem;
}
.pv0 {
	padding-top: 0;
	padding-bottom: 0;
}
.pv1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.pv2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.pv3 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.pv4 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.pv5 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.pv6 {
	padding-top: 8rem;
	padding-bottom: 8rem;
}
.pv7 {
	padding-top: 16rem;
	padding-bottom: 16rem;
}
.ph0 {
	padding-left: 0;
	padding-right: 0;
}
.ph1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.ph2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.ph3 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.ph4 {
	padding-left: 2rem;
	padding-right: 2rem;
}
.ph5 {
	padding-left: 4rem;
	padding-right: 4rem;
}
.ph6 {
	padding-left: 8rem;
	padding-right: 8rem;
}
.ph7 {
	padding-left: 16rem;
	padding-right: 16rem;
}
.ma0 {
	margin: 0;
}
.ma1 {
	margin: 0.25rem;
}
.ma2 {
	margin: 0.5rem;
}
.ma3 {
	margin: 1rem;
}
.ma4 {
	margin: 2rem;
}
.ma5 {
	margin: 4rem;
}
.ma6 {
	margin: 8rem;
}
.ma7 {
	margin: 16rem;
}
.ml0 {
	margin-left: 0 !important;
}
.ml1 {
	margin-left: 0.25rem;
}
.ml2 {
	margin-left: 0.5rem;
}
.ml3 {
	margin-left: 1rem;
}
.ml4 {
	margin-left: 2rem;
}
.ml5 {
	margin-left: 4rem;
}
.ml6 {
	margin-left: 8rem;
}
.ml7 {
	margin-left: 16rem;
}
.ml-67 {
	margin-left: 6.7rem;
}
.mr0 {
	margin-right: 0;
}
.mr1 {
	margin-right: 0.25rem;
}
.mr2 {
	margin-right: 0.5rem;
}
.mr3 {
	margin-right: 1rem;
}
.mr4 {
	margin-right: 2rem;
}
.mr5 {
	margin-right: 4rem;
}
.mr6 {
	margin-right: 8rem;
}
.mr7 {
	margin-right: 16rem;
}
.mb0 {
	margin-bottom: 0 !important;
}
.mb1 {
	margin-bottom: 0.25rem;
}
.mb2 {
	margin-bottom: 0.5rem !important;
}
.mb3 {
	margin-bottom: 1rem !important;
}
.mb4 {
	margin-bottom: 2rem;
}
.mb5 {
	margin-bottom: 4rem;
}
.mb6 {
	margin-bottom: 8rem;
}
.mb7 {
	margin-bottom: 16rem;
}
.mt0 {
	margin-top: 0 !important;
}
.mt1 {
	margin-top: 0.25rem !important;
}
.mt2 {
	margin-top: 0.5rem;
}
.mt3 {
	margin-top: 1rem !important;
}
.mt4 {
	margin-top: 2rem !important;
}
.mt4-2 {
	margin-top: 2.2rem !important;
}
.mt5 {
	margin-top: 4rem;
}
.mt6 {
	margin-top: 8rem;
}
.mt7 {
	margin-top: 16rem;
}
.mv0 {
	margin-top: 0;
	margin-bottom: 0;
}
.mv1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.mv2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
.mv3 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.mv4 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.mv5 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.mv6 {
	margin-top: 8rem;
	margin-bottom: 8rem;
}
.mv7 {
	margin-top: 16rem;
	margin-bottom: 16rem;
}
.mh0 {
	margin-left: 0;
	margin-right: 0;
}
.mh1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.mh2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.mh3 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mh3-5 {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}
.mh4 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.mh5 {
	margin-left: 4rem;
	margin-right: 4rem;
}
.mh6 {
	margin-left: 8rem;
	margin-right: 8rem;
}
.mh7 {
	margin-left: 16rem;
	margin-right: 16rem;
}
.w-auto {
	width: auto;
}
.d-inline-block {
	display: inline-block;
}
.wdx100 {
	width: 100px !important;
}
.wd1 {
	width: 0.25rem;
}
.wd2 {
	width: 0.5rem !important;
}
.wd3 {
	width: 1rem !important;
}
.wd4 {
	width: 2rem !important;
}
.wd5 {
	width: 5rem !important;
}
.wd55 {
	width: 5.5rem !important;
}
.wd6 {
	width: 6rem !important;
}
.wd7 {
	width: 7rem;
}
.wd8 {
	width: 8rem;
}
.wd10 {
	width: 10rem !important;
}
.wp5 {
	width: 5% !important;
}
.wp10 {
	width: 10% !important;
}
.wp13 {
	width: 13% !important;
}
.wp15 {
	width: 15% !important;
}
.wp16 {
	width: 16% !important;
}
.wp17 {
	width: 17% !important;
}
.wp19 {
	width: 19% !important;
}
.wp20 {
	width: 20% !important;
}
.wp25 {
	width: 25% !important;
}
.wp30 {
	width: 30% !important;
}
.wp29 {
	width: 29% !important;
}
.wp65 {
	width: 65% !important;
}

.strike {
	text-decoration: line-through;
}
.underline {
	text-decoration: underline;
}
.no-underline {
	text-decoration: none;
}
.fw-normal {
	font-weight: normal;
}
.fw-bold,
.bold {
	font-weight: bold;
}
.block {
	display: block;
}
.noresize {
	resize: none;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}

/*

  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large

*/
.tl {
	text-align: left;
}
.tr {
	text-align: right;
}
.tc {
	text-align: center;
}
.tj {
	text-align: justify;
}
/*

   TEXT TRANSFORM
   Docs: http://tachyons.io/docs/typography/text-transform/

   Base:
     tt = text-transform

   Modifiers
     c = capitalize
     l = lowercase
     u = uppercase
     n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.ttc {
	text-transform: capitalize;
}
.ttl {
	text-transform: lowercase;
}
.ttu {
	text-transform: uppercase;
}
.ttn {
	text-transform: none;
}
/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale
     7 = 7th step in size scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
*/
/*
 * For Hero/Marketing Titles
 *
 * These generally are too large for mobile
 * so be careful using them on smaller screens.
 * */
.f-6,
.f-headline {
	font-size: 6rem;
}
.f-5,
.f-subheadline {
	font-size: 5rem;
}
/* Type Scale */
.f1 {
	font-size: 3rem;
}
.f2 {
	font-size: 2.25rem;
}
.f3 {
	font-size: 1.5rem;
}
.f4 {
	font-size: 1.25rem;
}
.f5 {
	font-size: 1rem;
}
.f6 {
	font-size: 0.875rem;
}
.f7 {
	font-size: 0.75rem;
}
.fs16 {
	font-size: 16px;
}

.fheight {
	height: 100%;
}
.ls075 {
	letter-spacing: 0.75px;
}
.textline {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.overhide {
	overflow: hidden;
}
.overauto {
	overflow: auto;
}
.in-block {
	display: inline-block;
}
.block {
	display: block;
}
.pointer {
	cursor: pointer !important;
}
.cursor-default {
	cursor: default !important;
	tr {
		cursor: default !important;
	}
	td {
		cursor: default !important;
	}
}
.w150 {
	width: 150px !important;
}
.w130 {
	width: 130px !important;
}
.w100 {
	width: 100px !important;
}
.w80 {
	width: 80px !important;
}
.w50 {
	width: 50px !important;
}
.w30 {
	width: 30px !important;
}
.vtop {
	vertical-align: top !important;
}
.vmiddle {
	vertical-align: middle !important;
}
.no-break {
	word-break: normal !important;
}
.tx-danger {
	color: $color-danger;
}
.tx-primary {
	color: $color-primary;
}
.tx-warning {
	color: $color-warning;
}
.tx-success {
	color: $color-success;
}

.bg-danger {
	background-color: $color-danger;
}
.bg-primary {
	background-color: $color-primary;
}
.bg-warning {
	background-color: $color-warning;
}
.bg-success {
	background-color: $color-success;
}
.w-inherit {
	width: inherit !important;
}

.opa-5 {
	opacity: 0.5;
}

.opa-3 {
	opacity: 0.3;
}

.tight {
	width: 1%;
	white-space: nowrap;
}

table {
	td {
		&.fit {
			@extend .tight;
			&-center {
				@extend .tight;
				text-align: center;
			}
			&-left {
				@extend .tight;
				text-align: left;
			}
			&-right {
				@extend .tight;
				text-align: right;
			}
		}
		&.strong {
			font-weight: bold;
		}
	}
	th {
		&.fit {
			@extend .tight;
			&-center {
				@extend .tight;
				text-align: center;
			}
			&-left {
				@extend .tight;
				text-align: left;
			}
			&-right {
				@extend .tight;
				text-align: right;
			}
		}
	}
}
