/*@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto:400,700');*/

// @font-face {
//     font-family: "Lato";
//     src: url("../fonts/lato-bold.woff2") format("woff2"),
//         url("../fonts/lato-bold.woff") format("woff");
//     font-weight: 700;
//     font-style: normal;
// }
// @font-face {
//     font-family: "Lato";
//     src: url("../fonts/lato-regular.woff2") format("woff2"),
//         url("../fonts/lato-regular.woff") format("woff");
//     font-weight: 400;
//     font-style: normal;
// }

@font-face {
	font-family: "Poppins";
	src: url("../fonts/Poppins-Bold.eot");
	src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Poppins-Bold.woff2") format("woff2"),
		url("../fonts/Poppins-Bold.woff") format("woff"),
		url("../fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Poppins";
	src: url("../fonts/Poppins-Regular.eot");
	src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Poppins-Regular.woff2") format("woff2"),
		url("../fonts/Poppins-Regular.woff") format("woff"),
		url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../fonts/Poppins-Medium.eot");
	src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Poppins-Medium.woff2") format("woff2"),
		url("../fonts/Poppins-Medium.woff") format("woff"),
		url("../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Google Sans Text";
	src: url("../fonts/google-sans-text/GoogleSansText-Regular.eot");
	src: url("../fonts/google-sans-text/GoogleSansText-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/google-sans-text/GoogleSansText-Regular.woff2") format("woff2"),
		url("../fonts/google-sans-text/GoogleSansText-Regular.ttf") format("truetype"),
		url("../fonts/google-sans-text/GoogleSansText-Regular.svg#GoogleSansText-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Google Sans Text";
	src: url("../fonts/google-sans-text/GoogleSansText-Bold.eot");
	src: url("../fonts/google-sans-text/GoogleSansText-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/google-sans-text/GoogleSansText-Bold.woff2") format("woff2"),
		url("../fonts/google-sans-text/GoogleSansText-Bold.ttf") format("truetype"),
		url("../fonts/google-sans-text/GoogleSansText-Bold.svg#GoogleSansText-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Google Sans Text";
	src: url("../fonts/google-sans-text/GoogleSansText-Medium.eot");
	src: url("../fonts/google-sans-text/GoogleSansText-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/google-sans-text/GoogleSansText-Medium.woff2") format("woff2"),
		url("../fonts/google-sans-text/GoogleSansText-Medium.ttf") format("truetype"),
		url("../fonts/google-sans-text/GoogleSansText-Medium.svg#GoogleSansText-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

html.h-auto,
body.h-auto {
	height: auto;
}
body {
	font-family: "Google Sans Text", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	// font-family: monospace;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.25;
	letter-spacing: -0.15px;
	color: #404040;
	text-align: left;
	background-color: #ecf0f5;
	overflow-y: auto;
	overflow-x: hidden;
	word-spacing: 2px;
	&>.wrapper {
		background-color: #fff;
		height: unset;
		.content-wrapper {
			background-image: url("../images/skulls.png");
			background-repeat: repeat;
			background-size: contain;
			background-attachment: fixed;
			min-height: 100vh;
			&.day {
				&-1 {
					background-image: url("../images/bg/1.png");
				}
				&-2 {
					background-image: url("../images/bg/2.png");
				}
				&-3 {
					background-image: url("../images/bg/3.png");
				}
				&-4 {
					background-image: url("../images/bg/4.png");
				}
				&-5 {
					background-image: url("../images/bg/5.png");
				}
				&-6 {
					background-image: url("../images/bg/6.png");
				}
				&-7 {
					background-image: url("../images/bg/7.png");
				}
			}
		}
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Google Sans Text", -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	font-weight: 700;
	line-height: 1.2;
}

footer {
	position: fixed;
	bottom: 0;
	width: 100%;
}

a,
a:focus,
a:hover {
	font-weight: normal;
	text-decoration: none;
}
label {
	color: $color-darker;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: -0.5px;
}
legend {
	font-size: 17px;
	margin-bottom: 1rem;
}
a:focus,
input:focus,
textarea:focus,
select:focus {
	outline-color: $color-dark;
}
.btn {
	border-radius: 4px;
	&.disabled,
	&:focus {
		outline: none;
	}
}
a[disabled],
a.disabled {
	cursor: not-allowed;
}
.panel-default {
	border-color: #b7b7b7;
	/*
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    */
	.panel-heading {
		border-color: transparent;
	}
}
.nav-tabs {
	padding: 0 5px;
}
.skin-green .main-header .navbar {
	background-color: $color-dark;
}
main {
	min-height: 88%;
	min-height: 88vh;
}
.home-logo {
	height: 70vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	&.CZ-logo {
		img {
			width: 150px;
		}
	}
	img {
		width: 350px;
		align-self: auto;
	}
}
.pos-info {
	float: right;
}
.money {
	text-align: right !important;
}
.content-header {
	min-height: auto;
	&>.breadcrumb>li {
		&+li:before {
			display: none;
		}
	}
}
.server-message {
	display: none;
}
.sticky-wrapper {
	z-index: 1000;
	position: relative;
}
// .nowrap {
// 	white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
.flash-box {
	-webkit-animation: flash-box-shadow ease-in-out 1s;
	animation: flash-box-shadow ease-in-out 1s;
}
.dropdown.open span.caret {
	transform: rotate(180deg);
}
@-webkit-keyframes flash-box-shadow {
	0% {
		box-shadow: none;
	}
	50% {
		box-shadow: 0px 0px 10px #098431;
	}
	100% {
		box-shadow: none;
	}
}

@keyframes flash-box-shadow {
	0% {
		box-shadow: none;
	}
	50% {
		box-shadow: 0px 0px 10px #098431;
	}
	100% {
		box-shadow: none;
	}
}
.flash-bg {
	-webkit-animation: flash-bg ease-in-out 1s;
	animation: flash-bg ease-in-out 1s;
}
@-webkit-keyframes flash-bg {
	0% {
		background-color: inherit;
	}
	50% {
		background-color: #098431;
	}
	100% {
		background-color: inherit;
	}
}

@keyframes flash-bg {
	0% {
		background-color: inherit;
	}
	50% {
		background-color: #098431;
	}
	100% {
		background-color: inherit;
	}
}

input[type="text"] {
	text-transform: uppercase;
}

@-webkit-keyframes shine {
	from {
		-webkit-mask-position: 150%;
	}
	to {
		-webkit-mask-position: -50%;
	}
}
.navbar-header {
	.navbar-brand:hover {
		-webkit-mask-image: linear-gradient(-75deg,
				rgba(0, 0, 0, 0.6) 30%,
				#000 50%,
				rgba(0, 0, 0, 0.6) 70%);
		-webkit-mask-size: 200%;
		animation: shine 2s infinite;
	}
}

.blink {
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
	color: red;
	font-weight: bold;
}
@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

.tooltip-inner {
	padding: 5px 10px;
	line-height: 16px !important;
}
.tooltip.top {
	margin-top: 0;
}
/*
  .tooltip-arrow {
      bottom: -15px;
  }
*/

.row.flexy {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: flex-start;
	label {
		margin-bottom: 2px;
		display: block;
	}
	.form-group {
		padding: 0 15px;
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		width: auto !important;
		text-transform: uppercase;
		&:last-child {
			margin-bottom: 2px;
		}
	}
	.col {
		order: 0;
		padding: 0 15px;
		flex: 0 1 auto;
		align-self: auto;
		width: auto !important;
		text-transform: uppercase;
	}
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #000;
	opacity: 0.6;
	/* Firefox */
}

.g-recaptcha {
	display: table;
	margin: 0 auto;
}

.form-group:last-child {
	margin-bottom: 2px !important;
}
.row {
	.form-group:last-child {
		margin-bottom: 2px !important;
	}
}
.panel-body .form-group,
form .form-group {
	&:last-child {
		margin-bottom: 2px !important;
	}
}

label {
	color: #242424;
	font-size: 12px;
	&.required::after {
		content: "*";
		color: red;
		margin-left: .35rem;
		position: relative;
		top: 2px;
	}
}
label.custom-file-upload {
	text-transform: none;
	font-size: 14px;
	border-radius: 4px;
	line-height: 1.3;
	position: relative;
	top: 1px;
}

.check-box,
.radio-box {
	display: flex;
	column-gap: 2rem;
	flex-wrap: wrap;
}

.radio,
.checkbox {
	label {
		&.inline {
			display: inline-flex;
			align-items: center;
			padding-right: 1rem;
			input[type="radio"],
			input[type="checkbox"] {
				position: inherit;
				margin-right: .5rem;
				margin-top: 0;
			}
		}
	}
}

fieldset {
	margin: 0;
	padding: 0;
	legend {
		margin-left: 0;
		margin-right: 0;
	}
}

.scrbar::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
fieldset::-webkit-scrollbar,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar,
body::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.scrbar::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
fieldset::-webkit-scrollbar-track,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.modal-body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrbar::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
fieldset::-webkit-scrollbar-thumb,
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.modal-body::-webkit-scrollbar-thumb {
	background-color: $color-dark;
	outline: 1px solid $color-darker;

}

.table-sm> :not(caption)>*>* {
	padding: 0.25rem 0.5rem !important;
}

table {
	th.fit,
	td.fit {
		width: 1%;
	}
	th {
		font-size: 90%;
		line-height: 1.75 !important;
		font-weight: 500;
	}
	&.table {
		&.table-valign-middle {
			&>thead>tr>th,
			&>tbody>tr>td {
				vertical-align: middle !important;
			}
		}
		&.input-border-0 {
			td input {
				border-width: 0;
				box-shadow: none;
				width: 100%;
			}
		}
		td {
			label {
				margin: 0;
			}
		}
	}
}