$color-green: #337240;
$color-green-lite: #5e9e6b;
$color-lightblue: #7895ad;
$color-black: #000;
$color-lite: #cdcdcd;
$color-dark: #4e4e4e;
$color-darker: #333333;

$color-primary: #286090;
$color-void: #ffdfdf;
$color-primary-border: #204d74;
$color-danger: #c9302c;
$color-danger-border: #ac2925;
$color-warning: #f0ad4e;
$color-warning-border: #eea236;
$color-success: #5cb85c;
$color-success-border: #4cae4c;

:root {
    --dt-row-selected: 51,122,183;
}

@import "adminlte";
@import "bootstrap";
@import "bootstrap533";
@import "global";
@import "components";
@import "pages";
@import "helper";
@import "print";
@import "mobile";
