/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    .container > .navbar-header {
        height: 40px;
        margin: 0 !important;
        margin-left: 0;
    }
    .nav > li > a {
        padding: 5px;
    }

    .home-logo {
        padding: 0 3rem;
        img {
            width: auto;
        }
    }
    .navbar {
        a,
        .dropdown-menu a {
            font-size: 1.5rem;
            line-height: 15px;
        }
        .dropdown:not(.user-menu) .dropdown-menu {
            position: relative;
            width: 100%;
            background-color: transparent;
            border: none;
            box-shadow: none;
            li a {
                color: #fff;
            }
        }
        .navbar-toggle {
            margin: 6px 0 0 0;
            i {
                top: -3px;
                position: relative;
            }
        }
        .navbar-collapse {
            background-color: $color-dark;
            max-height: initial;
            .navbar-nav {
                margin: 1rem 1rem 1.5rem 0rem;
            }
            .dropdown ul.dropdown-menu {
                display: none;
            }
        }
    }
    .navbar a.navbar-brand {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 15px;
    }
    .navbar-user-menu > .navbar-nav > li {
        padding: 0;
        top: 16px;
        position: relative;
        right: 0;
        height: 0;
        & > a {
            padding: 0;
        }
    }
    .main-header .navbar-user-menu {
        position: relative;
        .navbar-nav .dropdown-menu {
            top: 20px;
            li a {
                color: initial;
            }
        }
    }
    .nav-tabs > li {
        float: none;
        display: inline-block;
    }
    .pos-info {
        float: none;
        text-align: center;
        font-size: 10px;
    }
    .page-nota .box-body {
        display: block;
        padding: 4px;
    }
    table.table-filter thead tr.filters td .fa,
    table.table-filter thead tr.filters th .fa {
        // top: -4px;
    }
    table.table-filter thead tr.filters th {
        position: relative;
        input,
        select {
            width: 100%;
        }
    }
    .content-header > .breadcrumb {
		margin-bottom: 5px;
        & > li {
            margin-left: 0;
        }
    }
    .nav-tabs > li > a {
        line-height: 1.75rem;
    }
    .navbar .navbar-collapse .dropdown.open {
        ul.dropdown-menu {
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            li {
                display: inline-block;
                a {
                    padding: 6px 12px;
                    font-size: 13px;
                    white-space: normal;
                }
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
    main .container {
        padding-left: 10px;
		padding-right: 10px;
    }
    section.content-header {
        padding: 3px 0px;
        flex-direction: column;
    }
    section.content {
        padding: 5px 0px 30px;
    }
    .content-header > h1 {
        margin-top: 7px;
    }
    .navbar-brand {
        padding-left: 5px;
        line-height: 22px !important;
        left: 0;
    }
    .nav-tabs {
        display: flex;
        align-items: flex-end;
        padding-left: 0;
    }
    .box-body {
        padding: 5px 13px;
    }
    .tab-content {
        padding: 15px 0px;
    }
    div.dataTables_wrapper div.dataTables_filter label {
        float: right;
    }
    div.dataTables_wrapper div.dataTables_filter input {
        min-width: 11.5rem;
        width: 11.5rem;
    }
    .navbar-nav > .user-menu > .dropdown-menu {
        width: auto;
    }
    .row.flexy {
        .form-group {
            display: inline-block;
        }
    }
    fieldset {
        padding-left: 0;
        padding-right: 0;
    }
    header .navbar .container {
        padding-left: 7px;
        padding-right: 7px;
    }
    .modal .customer-trans .dataTables_wrapper {
        margin-bottom: 2rem !important;
    }
    .dl-sm dd {
        margin-left: 0;
    }
    .m-mb4 {
        margin-bottom: 2rem !important;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}
