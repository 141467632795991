.wrapper {
}

.main-header {
    height: 40px;
    min-height: 40px;
    position: relative;
    .navbar {
        height: 40px;
        min-height: 40px;
        z-index: 2;
    }
}
.navbar-header {
    margin-left: 10px !important;
}
.navbar-nav > .user-menu {
    .user-image {
        float: right;
        margin-left: 10px;
        margin-right: 0;
    }
}
.navbar-nav > .user-menu > .dropdown-menu {
    width: 0;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
    & > li.user-header {
        background-color: #367fa9;
        margin-right: -1px;
    }
    & > .user-footer {
        border: 1px solid #d2d6de;
        border-top-width: 0;
    }
}
.navbar-brand {
    padding: 10px;
    height: 40px !important;
    margin-right: 1rem;
    position: relative;
    left: -2px;
}
.navbar-nav > li > .dropdown-menu {
    border-bottom: 1px solid #d2d6de;
}
#navbar-collapse .navbar-nav li {
    .dropdown-menu {
        li {
            &:hover {
                background-color: $color-dark;
            }
            a {
                &:hover {
                    background-color: $color-dark;
                    color: #fff;
                }
            }
        }
    }
    /*
	&.dropdown.mega.open {
		.dropdown-menu {
			display: grid;
			min-width: 300px;
			grid-template-columns: 175px 140px;
			li {
				display: inline-block;
			}
		}
	}
	*/
}
.main-header {
    .navbar-main-menu {
        li i {
            font-size: 17px;
            position: relative;
            top: 2px;
            // background-image: url('../images/ticket.png');
            // width: 10px; height: 10px;
        }
    }
    .navbar-user-menu {
        padding: 0 15px !important;
        .navbar-nav {
            .dropdown-menu {
                padding: 5px 0;
                min-width: 120px !important;
                li {
                    padding: 0 5px;
                    &:hover {
                        background-color: $color-dark;
                    }
                    a {
                        text-align: right;
                        &:hover {
                            background-color: $color-dark;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
/*
.layout-top-nav .content-wrapper {
    margin-top: 0px;
}
.layout-top-nav .content-wrapper,
.layout-top-nav .main-footer {
    padding: 6px 0;
}
*/

.main-footer {
    font-size: 13px;
    z-index: 1000;
    color: #000;
    padding: 5px 0;
    .container {
        padding: 0 30px;
    }
}

.content-header {
    min-height: 61px;
    & > h1 {
        // font-size: 1.85rem;
        line-height: 31px !important;
        padding: 0 3px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 17.5px;
        font-family: "Poppins";
        color: $color-dark;
        font-weight: 700;
        letter-spacing: -0.75px;
        small {
            font-size: 17px;
            color: $color-darker;
            letter-spacing: -1px;
            line-height: inherit;
            padding-left: 6px;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;            
            &::before {
                background-image: url("../images/arrow-right.png");
                background-size: cover;
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 0.35rem;
                position: relative;
                top: 2px;
                content: "";
            }
        }
        .badge {
            margin-left: 10px;
            background-color: #337ab7;
            text-transform: uppercase;
            line-height: 13px;
        }
    }
}
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    font-size: 2.5rem;
}
section.content {
    padding: 0px 15px 30px 15px;
}

section.content-header {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
}

.dropdown-menu {
    font-size: inherit;
}
.content-header > .breadcrumb {
    float: none;
    position: unset;
    top: 0;
    background-color: transparent !important;
    display: flex;
    gap: 1rem;
    & > li > a.btn {
        color: #333;
        &-primary {
            color: #fff;
        }
    }
}
.navbar-nav > li > .dropdown-menu {
    border: 1px solid #d2d6de;
    border-top-width: 0;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
}
form {
    .separator {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        border-color: #ccc;
    }
    .form-group {
        margin-bottom: 10px;
        .checkbox {
            margin-top: 4px;
            margin-bottom: 4px;
            label {
                margin-right: 10px;
                padding-left: 17px;
                input[type="checkbox"] {
                    margin-left: -17px;
                }
            }
        }
    }
}
.form-group {
    div[data-field] {
        padding: 0 0 5px;
    }
}
.form-control-feedback {
    height: 28px;
    line-height: 29px;
}

.content-header > .breadcrumb {
    padding: 5px;
}
.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
}
#navbar-collapse .navbar-nav li .dropdown-menu li a:hover,
#navbar-collapse .navbar-nav li .dropdown-menu li:hover {
    background-color: $color-darker;
}

.navbar-main-menu {
    li:hover {
        background-color: $color-darker;
    }
}
.box,
.box.box-default {
    border-color: $color-lite;
}

.box-header,
.box-footer {
    padding: 15px;
}
.box-header {
    padding: 10px 10px 0px 10px;
    .box-title {
        font-weight: normal;
        font-size: 16px;
        margin: 5px 3px;
    }
}
.box-footer {
    text-align: center;
    background-color: #e5e5e5;
    border-bottom: 1px solid #b9b9b9;
    .btn {
        margin-right: 5px;
    }
}
.box-body {
    padding: 1.25rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    .tab-content {
        padding: 15px 0 5px;
    }
}

@media (max-width: 991px) {
    .navbar-user-menu > .navbar-nav > li > .dropdown-menu {
        right: 0;
    }
}
@media (min-width: 768px) {
}
@media (max-width: 768px) {
    .content,
    .content-header {
        padding-left: 0;
        padding-right: 0;
    }
}
