body.select-store {
	overflow: hidden;
	.selection-store {
		max-width: 300px;
		width: 100%;
		margin: auto;
		.ot-logo {
			margin-bottom: 3rem;
			text-align: center;
			img {
				width: 200px;
			}
		}
		.form-group {
			width: 100%;
		}
	}
	.main-footer {
		display: none;
	}
	form {
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		top: -7rem;
	}
}

.login-page {
	.content-wrapper {
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}
	.login-box {
		margin: -5rem auto 0;
		max-width: 350px;
	}
}

.page-nota {
	.content-wrapper {
		// background-image: none !important;
		background-color: #fff;
		.box.box-default {
			// background: url("../images/skulls.png");
			// background-repeat: repeat;
		}
		section.content {
			&>.box.box-default {
				background-image: linear-gradient(135deg,
						hsl(0deg 0% 100%) 0%,
						hsl(201deg 15% 96%) 43%,
						hsl(201deg 15% 91%) 60%,
						hsl(201deg 15% 87%) 71%,
						hsl(201deg 15% 82%) 80%,
						hsl(201deg 15% 78%) 86%,
						hsl(201deg 15% 74%) 92%,
						hsl(201deg 15% 69%) 95%,
						hsl(201deg 15% 65%) 98%,
						hsl(201deg 15% 61%) 100%);
			}
		}
		.content-header {}
	}
	.content-wrapper>.container-fluid {
		padding: 0 !important;
	}
	.box-body {
		padding: 9px 8px;
		display: grid;
		grid-template-columns: 18% 57% 23.5%;
		grid-gap: 10px;
		.first_column {
			grid-column-start: 1;
			grid-column: 1;
			grid-row: 1;
		}
		.second_column {
			grid-column-start: 2;
			grid-column: 2;
			grid-row: 1;
		}
		.third_column {
			grid-column-start: 3;
			grid-column: 3;
			grid-row: 1;
		}
		.select-item {
			.amount {
				font-size: 15px;
				line-height: 20px;
				padding: 4px 5px 4px 0 !important;
				background-color: #e4e4e4;
			}
			&.body-inside {
				.panel-footer {
					margin-left: -1rem;
					margin-right: -1rem;
				}
			}
			.panel {
				.panel-body {
					padding-bottom: 5px;
				}
			}
		}
		.summary {
			h5 {
				width: 100%;
				display: block;
				font-size: 15px;
				font-weight: 700;
				margin: 0 0 5px 0;
			}
			.summary-items {
				display: grid;
				grid-template-columns: 66% 1fr;
			}
			.summary-payleft,
			.summary-subtotal,
			.summary-total_additional,
			.summary-total {
				margin-top: 1rem;
				padding: 5px 0;
				border-top: 1px solid #ddd;
				display: grid;
				grid-template-columns: 66% 1fr;
				[label] {
					text-align: right;
				}
			}
			.summary-payleft {
				margin-top: 0;
				padding: 10px 0;
			}
			.summary-total {
				margin-top: 0;
			}
			.summary-additional {
				display: grid;
				grid-template-columns: 66% 1fr;
			}
			.summary-payments {
				border-top: 1px solid #ddd;
				text-align: right;
				padding-top: 5px;
				/*
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 66% 1fr;
                }
                */
				display: grid;
				grid-template-columns: 66% 1fr;
				h5 {
					width: 100%;
					padding: 8px 0 0;
					text-align: left;
				}
				[paytotal] {
					@extend .money;
					padding: 5px 0;
					font-weight: bold;
				}
			}
		}
		.items {
			padding: 0 !important;
			.panel-body {
				padding: 0;
				table {
					border-width: 0;
					&[name="table_items"] {
						td {
							.item-name {
								white-space: normal;
								font-weight: bold;
								line-height: 18px;
								padding-top: 2px;
								margin-bottom: 2px;
							}
						}
					}
				}
			}
		}
		.select-payment {
			padding: 10px 0 0 !important;
			.tab-content {
				padding: 10px 14px;
			}
			.panel-footer {
				padding: 10px !important;
			}
		}
		.payment {
			.panel-body {
				padding: 0;
				table {
					border-width: 0;
					td {
						.pay_card_desc {
							display: block;
							div {
								line-height: 20px;
								text-transform: uppercase;
								label {
									margin: 0;
									font-weight: normal;
									text-transform: none;
									width: 60px;
								}
							}
						}
					}
				}
			}
		}
		.actions {
			margin: 3rem auto 1rem;
			text-align: center;
		}
		.section-title {
			font-weight: normal;
			margin: 0 0 1rem 0;
		}
		.panel {
			margin-bottom: 10px;
			&:hover {
				.panel-heading {
					background-color: #e4e4e4;
				}
			}
			.panel-heading {
				font-size: 1.35rem;
				background-color: #f5f5f5;
				padding: 5px 10px 2px;
				line-height: 1.5;
			}
			.panel-body {
				padding: 10px;
			}
			.panel-footer {
				padding: 10px !important;
			}
		}
		.body-inside {
			padding: 10px;
			&.body-fit {
				padding: 0;
			}
			.panel-footer {}
		}
		// .form-group {
		//     margin-top: 0px;
		//     margin-bottom: 10px;
		// }
		.customer-create {
			.row {
				padding: 0 2rem;
			}
		}
	}
	.summary {
		.panel-body {
			padding-bottom: 0 !important;
		}
		[label] {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			grid-column-start: 1;
			grid-column: 1;
		}
		[amount] {
			@extend .money;
			font-weight: bold;
			grid-column-start: 2;
			grid-column: 2;
		}
	}
}

.transaction-order_receipt {
	.model-frame {
		.flexy {
			justify-content: space-between;
			align-content: space-between;
			align-items: flex-start;
			margin: 5px -5px 10px;
			img {
				cursor: pointer;
				border: 1px solid #fff;
				padding: 5px;
				border-radius: 3px;
				margin: auto;
				&:hover {
					border-color: #ccc;
				}
				&.active {
					border-color: #337ab7;
					box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}

.helpdesk {
	.timeline_wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;
	}
	.timeline_sidebar {
		width: 17%;
		padding-right: 2rem;
	}
	.timeline_content {
		flex-grow: 1;
		.timeline>.time-label>span {
			margin-left: 19px;
		}
		.timeline-item {
			.timeline-header {
				padding-top: 5px;
				padding-bottom: 5px;
				text-transform: uppercase;
			}
			.timeline-body {
				padding-top: 0;
				padding-bottom: 5px;
				font-size: 15px;
			}
			.timeline-attach {
				padding: 10px;
				.attachments {
					width: 100px;
					height: auto;
					cursor: pointer;
				}
			}
			.timeline-footer {
				font-size: 11px;
				span.tag {
					background-color: #ccc;
					display: inline-block;
					padding: 2px 5px;
					margin-right: 5px;
				}
			}
		}
	}
}

.utilitas-shift {
	.shift_dates .datepicker td.shifted {
		background-image: linear-gradient(to bottom, #00cc2e, #1c6e2e);
		color: #fff !important;
	}
	.shift_dates .datepicker td.shifted:hover {
		background-image: linear-gradient(to bottom, #00cc2e, #1c6e2e);
		color: #fff !important;
	}
	.table-shift tr {
		&.shift {
			background-color: #d1e0ed !important;
		}
		&.shifted {
			background-color: #e3f4e3 !important;
		}
	}
}

body.profile {
	input[type="password"] {
		text-transform: none !important;
	}
	.box-body {
		padding: 1.5rem;
	}
}

.master {
	&-customer {
		div.filters {
			margin-bottom: 1.5rem;
			display: flex;
			&>div {
				margin-right: 1rem;
			}
		}
	}
}