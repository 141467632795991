.select2-container {
    width: 100% !important;
    .select2-selection {
        min-height: 28px !important;
        border-radius: 4px;
    }

    .select2-selection__rendered {
        .select2-selection__clear {
            margin-top: 0;
            margin-right: 0;
            position: absolute;
            right: 20px;
        }
        .select2-selection__choice {
            margin-top: 3px;
            color: #666;
            clear: both;
            .select2-selection__choice__remove {
                margin-bottom: 1px;
                position: relative;
                top: -1px;
            }
        }
    }
}
.select2-container--default {
    /*
    .select2-selection--multiple {
        .select2-selection__rendered {
            li.select2-selection__choice {
                div {
                    float: left;
                    margin-right: 5px;
                }
            }
        }
    }
    */
    .select2-selection--single {
        padding: 4px 0px;
        border-color: #ccc;
        .select2-selection__rendered {
            line-height: 26px;
            text-transform: uppercase;
        }
        .select2-selection__arrow {
            height: 30px;
            top: 0;
            right: 0;
        }
    }
    &.select2-container--open,
    &.select2-container--focus {
        .select2-selection {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                0 0 8px rgba(102, 175, 233, 0.6);
            border: 1px solid #204c29 !important;
        }
    }
    .select2-checked {
        padding: 0 8px;
        display: block;
        margin-top: 5px;
        input[type="checkbox"] {
            top: 2px;
        }
    }
}
.select2-results__option {
    padding: 3px 9px 4px;
}
.modal-dialogue {
    vertical-align: top !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    .modal-content {
        background-color: transparent;
        .modal-header {
            min-height: 16.43px;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid #e5e5e5;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            display: block;
            &.bootstrap-dialog-draggable {
                cursor: move;
            }
            .close {
                color: #fff;
                opacity: 1;
            }
        }
        .modal-body {
            background-color: white;
            max-height: 75vh;
            overflow: auto;
            padding: 15px;
            overflow-y: auto;
            overflow-x: hidden;
            .bootstrap-dialog-message {
                color: #495057;
                padding: 0;
                .dataTables_wrapper {
                    margin: -1px -1px 1px;
                }
                .tab-content {
                    padding: 10px 0 0px;
                }
                .tab-content > .tab-pane {
                    padding: 10px;
                }
                .form-group:last-child {
                    margin-bottom: 5px;
                }
            }
        }
        .modal-footer {
            text-align: right;
            padding: 10px 13px;
            border: 1px solid #d8dadc;
            background-color: #e5e5e5;
            .bootstrap-dialog-footer-buttons {
                .btn {
                    padding: 0.25rem 1rem;
                    margin-left: 5px;
                    &.pull-left:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
.modal {
    .modal-body {
        .bootstrap-dialog-message {
            .tab-content {
                .tab-pane {
                    padding: 0 !important;
                    .form-group {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    &.size-normal {
        .modal-dialog {
            @extend .modal-dialogue;
            width: 75%;
        }
        &.full-screen {
            padding: 0 !important;
            .modal-dialog {
                @extend .modal-dialogue;
                width: 100%;
                margin: 0 auto;
                .modal-content .modal-body {
                    min-height: 86.2vh;
                }
            }
        }
        &.width-wrap {
            .modal-dialog {
                @extend .modal-dialogue;
                width: min-content;
                .bootstrap-dialog-title,
                .bootstrap-dialog-body,
                .bootstrap-dialog-footer {
                    white-space: nowrap;
                }
            }
        }
        &.width-xss {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 25%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-xs {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 34%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-sm {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 43%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-md {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 55%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-lg {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 68%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-xl {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 82%;
                @media (max-width: 767.98px) {
                    width: 95%;
                }
                max-width: initial;
            }
        }
        &.width-xxl {
            .modal-dialog {
                @extend .modal-dialogue;
                width: 95%;
                max-width: initial;
                .modal-footer {
                    .bootstrap-dialog-footer-buttons {
                        .btn.btn-block {
                            display: inline-block;
                            width: auto;
                            vertical-align: inherit;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &.height-md {
            .modal-dialog {
                .modal-body {
                    max-height: 45vh !important;
                }
            }
        }
        &.height-lg {
            .modal-dialog {
                .modal-body {
                    max-height: 70vh !important;
                }
            }
        }
    }
    &.type-success {
        .modal-body {
        }
    }
}
.modal {
    .customer-trans {
        padding: 0;
        margin-top: 0.75rem;
        .dataTables_wrapper {
            .table-fullwidth .row:last-child {
                div {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    .nota-popup {
        fieldset {
            padding: 0;
        }
    }
}
.bootstrap-dialog .bootstrap-dialog-close-button {
    margin-right: 5px;
}
.daterangepicker {
    table {
        td {
            padding: 1px !important;
            &.in-range {
                background-color: #c1d4dc;
            }
            &.end-date {
                background-color: #357ebd;
            }
        }
    }
}
.form-control-three {
    label {
        display: block;
    }
    input,
    select,
    textarea {
        width: 30%;
        display: inline-block;
    }
}
.main-nav {
    position: relative;
}
.daterangepicker {
    .calendar-table {
        td,
        th {
            min-width: 28px;
            width: 28px;
            height: 24px;
            line-height: 22px;
        }
        th {
        }
        td {
        }
    }
}
div[data-notify] {
    z-index: 1100 !important;
    span[data-notify="message"] {
        font-size: 16px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    }
}
/* parsley */
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
    /*
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
  */
}
input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    border: 1px solid #d73925;
}
.parsley-errors-list {
    color: #d73925;
    margin: 5px 0 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.9em;
    line-height: 0.9em;
    opacity: 0;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}
.parsley-errors-list.filled {
    opacity: 1;
}
/* end parsley */

.nanobar {
    position: fixed !important;
}
.nanobar > .bar {
    background-image: url("../images/rainbow.gif");
    background-size: cover;
    height: 2px;
}
.nanobar .bar {
    display: none;
    &:first-child {
        display: block;
    }
}
.blockUI {
    background-color: #ecf0f5 !important;
    color: #495057 !important;
    border-radius: 4px;
    &.blockOverlay {
        z-index: 50000 !important;
        opacity: 0 !important;
    }
    &.blockMsg {
        display: none !important;
        /*
		padding: 10px 0 !important;
		width: 15% !important;
		left: 40% !important;
        z-index: 50001;
        border: 1px solid #204c29 !important;
		.dtproc {
			background-image: url('../images/datatables/dataTables_processing.gif');
			width: 100%;
		    height: 35px;
		    background-repeat: no-repeat;
		    margin: 0 auto;
		    background-position: center -15px;
		}
        */
    }
}
.dl-sm {
    dt {
        width: 55px;
    }
    dd {
        margin-left: 75px;
    }
}
.text-sm {
    font-size: 12px;
}
select.no-shadow + span.select2 {
    box-shadow: none !important;
    border-width: 0 !important;
}
.vmid {
    vertical-align: middle !important;
}
hr {
    border-top: 1px solid #e5e5e5;
}
form {
    &.form-slim {
        legend {
            margin-bottom: 10px;
            padding-bottom: 5px;
            font-size: 17px;
        }
        .form-group {
            margin-bottom: 0;
            label {
                padding-right: 0 !important;
                margin-bottom: 2px;
            }
            p {
                min-height: 15px;
            }
        }
    }
}
.label-middle {
    display: inline-block;
    text-align: center;
    float: left;
    padding-top: 0.5rem;
}
.view-pdf iframe {
    width: 100%;
    height: 600px;
}
.datepicker {
    .datepicker-months {
        table > tbody > tr > td {
            .month {
                height: 30px;
                line-height: 30px;
            }
        }
    }
}

select.form-control {
    padding: 3px 6px 3px 2px;
}
.ots-cam {
    position: fixed;
    bottom: 0;
    left: 50%;
	transform: translateX(-50%);
    display: inline-block;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #ccc;
    width: 432px;
    z-index: 100000000;
    &[mode="barcode"] {
        height: 120px;
        video {
            height: 220px !important;
        }
    }
    &[mode="snap"] {
        height: 248px;
		video {
			width: 420px !important;
			height: 236.25px !important;
		}
    }
	#cam_screen {
		height: 248px !important;
	}
    video {
        -webkit-transition: all 200ms ease-out;
        -moz-transition: all 200ms ease-out;
        -o-transition: all 200ms ease-out;
        transition: all 200ms ease-out;
        cursor: move;
    }
    .closed {
        position: absolute;
        top: -9px;
        right: -7px;
        .fa {
            font-size: 2em;
        }
    }
}
.cam-container {
    .the-shutter {
        margin-bottom: 10px;
    }
    .the-captured {
        .attachments img {
            width: 420px;
            height: auto;
        }
    }
}
i.dots {
    background-image: url("../images/dots.gif");
    width: 24px;
    height: 8px;
    display: inline-block;
}
dl.dl-inline {
    float: left;
    dt {
        display: block;
    }
    dd {
        display: block;
    }
}
.fit {
    width: 1%;
    white-space: nowrap;
    &-center {
        width: 1%;
        white-space: nowrap;
        text-align: center !important;
    }
    &-right {
        width: 1%;
        white-space: nowrap;
        text-align: right !important;
    }
}
.unfit {
    width: 20%;
    white-space: normal !important;
}

table {
    &.table-bordered > thead > tr > th {
        background-color: #e9e9e9;
    }
    tr.selected,
    tr.selected:hover {
        background-color: #c3c3c3 !important;
        td a {
            color: inherit !important;
        }
    }
    &.table {
        thead {
            background-color: #eee;
        }
        & > thead > tr > th.vmid {
            @extend .vmid;
        }
        & > thead > tr > td.vmid {
            @extend .vmid;
        }
        & > tbody > tr > td.vmid {
            @extend .vmid;
        }
    }
    &.table-browse {
        tr {
            cursor: pointer;
        }
    }
    &.table-filter {
        thead {
            tr.filters {
                background-color: #eaeaea;
                td,
                th {
                    border-bottom-width: 1px;
                    padding: 5px;
                    .fa {
                        position: relative;
                        left: 4px;
                        top: 5px;
                    }
                    select,
                    input {
                        width: 100%;
                        padding: 3px 5px;
                        font-weight: normal;
                        height: 28px;
                        font-size: 12px;
                        border-radius: 4px;
                    }
                }
                th:first-child {
                    vertical-align: middle;
                    i {
                        position: initial;
                    }
                }
            }
        }
        tbody {
            tr {
                cursor: pointer;
            }
        }
    }
    &.table-condensed > thead > tr > th {
        padding: 3px 5px;
    }
    &.table-condensed > tbody > tr > td {
        padding: 2px 5px;
    }
    &.table-print {
        th {
            padding: 2px 5px !important;
        }
        td {
            padding: 0 5px !important;
            border-top-width: 0 !important;
        }
        .print-header {
            display: none;
        }
    }
    &.table-counter {
        counter-reset: rowNumber;
        tbody tr {
            counter-increment: rowNumber;
            td:first-child::before {
                content: counter(rowNumber);
            }
            td.counter {
                text-align: center;
            }
        }
    }
    &.table-fit {
        td,
        th {
            width: 1%;
            white-space: nowrap;
        }
    }
    tfoot {
        tr {
            td {
                border-top: 1px solid #ddd !important;
            }
        }
    }
    &.tr-cursor tbody tr {
        cursor: pointer;
    }
}
table.fixedHeader-floating {
    position: fixed !important;
    background-color: white;
    top: 33px !important;
    thead tr {
        background-color: #e7e7e7;
    }
}
table.fixedHeader-floating.no-footer {
    border-bottom-width: 0;
}
table.fixedHeader-locked {
    position: absolute !important;
    background-color: white;
}
@media print {
    table.fixedHeader-floating {
        display: none;
    }
}

div.dataTables_wrapper {
    div.dataTables_length {
        float: left;
        color: #908f8f;
        font-size: 14px;
        select {
            font-size: 14px;
            width: 60px;
            padding: 0 5px;
            margin: 0 2px 0 0;
            height: 28px;
        }
    }
    div.dataTables_filter {
        color: #908f8f;
        input {
            font-size: 14px;
            margin: 0 0 0 6px;
            min-width: 11.3em;
        }
    }
    table.dataTable {
        width: 100% !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        thead {
            .sorting:after,
            .sorting:before,
            .sorting_asc:after,
            .sorting_asc:before,
            .sorting_asc_disabled:after,
            .sorting_asc_disabled:before,
            .sorting_desc:after,
            .sorting_desc:before,
            .sorting_desc_disabled:after,
            .sorting_desc_disabled:before {
                bottom: 0.6em;
            }
            tr {
                background-color: #e2e2e2;
                th {
                    text-align: left;
                    border-bottom-color: #c6c7ca;
                    border-top-width: 0;
                    padding: 4px 8px;
                    color: #696969;
                    font-size: 12px;
                    letter-spacing: -0.5px;
                    text-transform: uppercase;
                    &.action {
                        padding: 4px 8px !important;
                    }
                }
            }
        }
        border-collapse: initial !important;
        border: 1px solid #d2d6de;
        td.dataTables_empty {
            display: none;
        }
        &.has-action {
            td {
                div {
                    line-height: 20px;
                }
                .attachments {
                    line-height: 16px;
                }
                &.action {
                    .btn {
                        margin: 4px 2px;
                        height: 22px;
                        line-height: 14px;
                        padding: 0 7px;
                        border-color: $color-dark;
                        &:hover {
                            border-color: #8c8c8c !important;
                        }
                    }
                    a,
                    a.btn {
                        height: 24px;
                        line-height: 22px;
                        border-color: $color-dark;
                    }
                }
                .btn:last-child {
                    margin-right: 3px;
                }
            }
        }
        th {
            white-space: nowrap;
            width: 1px;
            &.focus {
                box-shadow: none;
            }
            &.unfit {
                white-space: normal;
            }
            &.text-center {
                text-align: center;
            }
            &.unwrap {
                white-space: normal !important;
            }
        }
        td {
            width: auto;
            padding: 4px 8px;
            text-align: left;
            text-transform: uppercase;
            border-top-width: 0;
            vertical-align: top;
            white-space: nowrap;
            cursor: default;
            line-height: 1.5;
            .thumb {
                width: 25px;
                height: auto;
            }
            .thumb-md {
                width: 50px;
                height: auto;
            }
            &.focus {
                box-shadow: none;
            }
            &.bold {
                font-weight: bold;
            }
            &.nowrap {
                white-space: nowrap;
            }
            &.text-right {
                text-align: right !important;
            }
            &.text-center {
                text-align: center !important;
            }
            .dtr-details {
                .dtr-title {
                    display: block;
                }
            }
            .input-group {
                width: 100%;
            }
            &.unwrap {
                white-space: normal !important;
                word-wrap: break-word;
            }
        }
        tr {
            &.even {
                background-color: #eae9e9;
            }
            &.odd {
                background-color: #f2f2f2;
            }
            &.void {
                background-color: $color-void !important;
            }
        }
        .sequence {
            width: 1%;
            text-align: center;
            white-space: nowrap;
        }
        .action {
            width: 1%;
            padding: 0 1px;
            text-align: right;
            white-space: nowrap;
            label {
                margin-top: 5px;
            }
        }
        .unfit {
            white-space: normal;
        }
        &.table-fit {
            .form-control {
                width: inherit;
            }
        }
        tbody {
            td {
                padding: 6px 8px 4px;
            }
        }
    }
    div.dataTables_info {
        color: #908f8f;
        font-size: 14px;
        margin: 8px 0 0;
        padding: 0;
    }
    div.dataTables_paginate {
        margin: 4px 0 0;
        color: #908f8f;
        font-size: 14px;
        .pagination {
            margin: 2px 0 0 !important;
        }
        .pagination > li > a,
        .pagination > li > span {
            padding: 2px 8px;
            color: $color-black;
            &:focus {
                outline: 0 !important;
            }
        }
        .pagination > li.active > a {
            background-color: #e2e2e2;
            color: #575a6b;
            border-color: $color-dark;
        }
    }
    div.dataTables_processing {
        /*
		-webkit-box-shadow: 0px 0px 3px 2px rgba(32,76,41,1);
		box-shadow: 0px 0px 3px 2px rgba(32,76,41,1);
		*/
        border: 1px solid $color-dark;
        background-color: #ecf0f5;
        z-index: 10000;
        padding: 0.75em 0;
        position: fixed;
        .dtproc {
            background-image: url("../images/datatables/dataTables_processing.gif");
            width: 100%;
            height: 35px;
            background-repeat: no-repeat;
            margin: 0 auto;
            background-position: center -15px;
        }
        div:last-child {
            display: none;
        }
    }
    .DTFC_ScrollWrapper {
        .DTFC_LeftHeadWrapper {
            table {
                margin-bottom: 0 !important;
            }
        }
        .DTFC_RightHeadWrapper {
            table {
                margin-bottom: 0 !important;
            }
        }
        .DTFC_LeftBodyLiner {
            table {
                margin: 0 !important;
            }
        }
        .DTFC_RightBodyLiner {
            table {
                margin: 0 !important;
            }
        }
    }
    input,
    select {
        border-radius: 4px;
    }
}

.cam-container {
    margin: 3rem 0 1rem;
    .uploader {
        display: inline-block;
        position: relative;
        top: -4px;
        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            padding: 4px 7px;
            cursor: pointer;
            color: #404040;
            font-weight: normal;
            height: 28px;
            &:hover {
                background-color: #e6e6e6;
            }
        }
    }
    canvas {
        display: block;
        margin-bottom: 1rem;
    }
}

span.fa:before,
.fa:before {
    font-weight: 400;
}

.backtotop {
    display: inline-block;
    background-color: $color-dark;
    width: 30px;
    height: 30px;
    text-align: center;
    position: fixed;
    bottom: 36px;
    right: 10px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    &::after {
        content: "\f077";
        font-family: "Font Awesome 5 Pro";
        font-weight: 700;
        font-style: normal;
        font-size: 2rem;
        line-height: 30px;
        color: #fff;
    }
    &:hover {
        cursor: pointer;
        background-color: #333;
    }
    &:active {
        background-color: #555;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.has-popup {
    border-bottom: 1px dashed #666;
}

.medium-zoom-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 300ms;
    will-change: opacity;
}

.medium-zoom--opened .medium-zoom-overlay {
    cursor: pointer;
    cursor: zoom-out;
    opacity: 1;
    z-index: 100000;
}

.medium-zoom-image {
    cursor: pointer;
    cursor: zoom-in;
    z-index: 100001;
    /*
    The `transition` is marked as "!important" for the animation to happen
    even though it's overriden by another inline `transition` style attribute.

    This is problematic with frameworks that generate inline styles on their
    images (e.g. Gatsby).

    See https://github.com/francoischalifour/medium-zoom/issues/110
   */
    transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1) !important;
}

.medium-zoom-image--hidden {
    visibility: hidden;
}

.medium-zoom-image--opened {
    position: relative;
    cursor: pointer;
    cursor: zoom-out;
    will-change: transform;
}
