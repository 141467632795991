@media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: 0;
    }
}
.btn {
    height: 26px;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 0;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    letter-spacing: -0.5px;
    text-transform: initial;
    // min-width: 45px;
    &:hover {
        background-color: darken(#fff, 10);
        border-color: #ccc;
    }
}
.btn-default {
    @extend .btn;
    color: #333;
    &:focus,
    &:active,
    &:hover {
        color: #333 !important;
        background-color: #d4d4d4 !important;
        border-color: #8c8c8c !important;
    }
}
.btn-primary {
    @extend .btn;
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary-border;
    &:hover {
        background-color: darken($color-primary, 10);
    }
}
.btn-warning {
    @extend .btn-default;
}
.btn-danger {
    @extend .btn-default;
}
.btn-success {
    @extend .btn-primary;
}
.btn-info {
    @extend .btn-default;
}
.btn-print {
    @extend .btn-default;
    min-width: 0;
}
.btn-remove {
    @extend .btn-default;
    min-width: 0;
}
.btn-fit {
    @extend .btn-default;
    min-width: 0;
}
.alert {
    &-warning {
        background-color: #f9ba57 !important;
    }
}
.form-control {
    height: 28px;
    // border-radius: 0;
    padding: 3px 6px;
    text-transform: uppercase;
}
.form-control:focus {
    border-color: $color-dark !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 1px rgba(32, 76, 41, 0.6);
}
.bootstrap-dialog {
    .bootstrap-dialog-title {
        line-height: 28px;
        padding-left: 5px;
    }
    .bootstrap-dialog-message {
        font-size: 14px;
    }
}


.input-group {
    background-color: transparent;
    .form-control[type="text"],
    .form-control[type="date"],
    .form-control[type="number"],
    .form-control[type="email"],
    .form-control[type="password"] {
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
    }
    &.input-right {
        .form-control[type="text"],
        .form-control[type="date"],
        .form-control[type="number"],
        .form-control[type="email"],
        .form-control[type="password"] {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 0;
        }
    }
    .input-group-addon {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        padding: 4px 8px;
        border-color: #ccc;
        &.disabled {
            border-color: #ccc !important;
        }
        &:last-child {
            border-left: initial;
        }
    }
    .form-control {
        display: table-cell;
    }
    .btn-group {
        display: table-cell;
    }
    .input-group-btn {
        .btn {
            min-width: auto;
        }
    }
    &.input-right {
        input {
            border-left-width: 0;
        }
        .input-group-addon {
            border-width: 1px;
            border-style: solid;
            border-right-color: #ccc;
            position: relative;
        }
    }
    &[class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
        float: left;
    }
}

input[type="number"] { -moz-appearance: textfield; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { margin-left: .5rem; }

.nav-tabs {
    padding-left: 10px;
    & > li > a {
        padding: 5px 10px !important;
        &:hover {
            background-color: #ddd;
        }
    }
    li.active a {
        font-weight: bold;
        color: #333;
    }
    li.active ul.dropdown-menu li a {
        font-weight: normal;
        color: initial !important;
    }
}
.tab-content {
    padding: 15px 15px 10px 15px;
    .form-group {
        padding-left: 7px;
        padding-right: 7px;
    }
    .tab-pane {
    }
    &.fit {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        .tab-pane {
            padding: 0;
        }
    }
}

select.form-control {
    padding: 2px 4px 4px 1px;
}
.nav > li > a {
    padding: 10px;
}
.btn {
    line-height: 18px;
    height: 28px;
}
button {
    height: 28px;
}
input[type="checkbox"] {
    position: relative;
    top: -1px;
}
input[type="radio"] {
    position: relative;
    top: -1px;
}
.help-block {
    font-size: 11px;
    letter-spacing: -1px;
}
.caret {
    position: relative;
    top: -1px;
}
.form-group {
    &.checkbox {
        margin-top: initial;
    }
}
.checkbox,
.radio {
    margin-top: 0;
}
#navbar-collapse {
    .navbar-nav {
        li {
            .dropdown-menu {
                li {
                    padding: 0 8px 0 5px;
                }
            }
        }
    }
}
.dropdown-menu li {
    &:hover {
        a {
            color: #fff;
        }
    }
    a {
        font-size: 14px;
        padding: 3px 10px;
    }
}
.btn-primary + ul.dropdown-menu li {
    &:hover {
        background-color: $color-primary !important;
        a {
            background-color: $color-primary !important;
        }
    }
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.5;
}
a.disabled,
a[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
.input-group-addon {
    padding: 6px 8px;
}
button.btn:focus,
button.btn:active,
a.input-group-addon:focus {
    border-color: $color-dark !important;
    outline-color: $color-dark !important;
}
.table-hover > tbody > tr:hover {
    background-color: #e2e2e2 !important;
}
.table-striped {
    > tbody > tr:nth-of-type(odd) {
        background-color: #ececec;
    }
}
.form-control[disabled],
.form-control[readonly] {
    box-shadow: none;
    border-color: #ccc !important;
}
.form-group {
    &.inline-control {
        label {
            display: block;
            width: 25%;
        }
        .form-control {
            display: inline-block;
            width: auto;
        }
    }
    &.has-error {
        .select2-container--default {
            .select2-selection--single,
            .select2-selection--multiple {
                border-color: #dd4b39 !important;
            }
        }
        .form-control {
            border-color: #dd4b39 !important;
            box-shadow: none;
        }
    }
}

/* open on hover */
.dropdown:not(.user-menu):hover .dropdown-menu,
.btn-group:hover .dropdown-menu {
    display: block;
}
.dropdown-menu {
    margin-top: 0;
}
.dropdown-toggle {
    margin-bottom: 2px;
}
.navbar .dropdown-toggle,
.nav-tabs .dropdown-toggle {
    margin-bottom: 0;
}
.dropdown.mega:hover {
    .dropdown-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        li {
            display: inline-block;
        }
    }
}

.pl0 {
    padding-left: 0 !important;
}

textarea {
    resize: none;
    min-height: 48px;
}

@media (min-width: 1400px) {
    .container {
        width: 1320px;
    }
}
